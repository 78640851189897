/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import React, { useEffect, useRef, useState } from "react";
import { FunnelIcon } from "@heroicons/react/24/solid";
import DatePicker, { type ReactDatePicker } from "react-datepicker";
import { DateTableFormat, type Dispositions } from "@/types/callbotRecord";
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek } from "date-fns";
import { type DateRangeSelection } from "@/types/dashboard";
import CustomInput from "@/components/shared/customInput/customInput";
import {
  type ColumnFilter,
  DATE_RANGE_OPTIONS,
  DATE_TYPE,
  type FilterState,
} from "@/types/table";
import { InputType } from "@/components/shared/customInput/types";
import { type ColumnFiltersState } from "@tanstack/react-table";
import { UserSelector } from "@/components/user-selector";
import {
  type Option,
  type SelectValue,
} from "react-tailwindcss-select/dist/components/type";
import Select from "react-tailwindcss-select";
import {
  getActivityOptions,
  getStatusOptions,
} from "@/components/shared/table/helpers/helpers";
import { type UserExecutionByUser } from "@/types/user";
import { getAssigneeFullName } from "@/components/assigned/helpers/indext";
import { useClickOutside } from "@/helpers";
import classNames from "classnames";
import { useDispositions } from "@/services/CallsApiClient";

interface Props {
  columnFilters: ColumnFiltersState;
  setColumnFilters?: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
  onDateRangeChange: (date: DateRangeSelection) => void;
  dateRangeSelection: DateRangeSelection;
  showAssigneeFilter: boolean;
  showStatusFilter: boolean;
  users?: UserExecutionByUser[];
}

export function ShowFilter({
  columnFilters,
  setColumnFilters,
  onDateRangeChange,
  dateRangeSelection,
  showAssigneeFilter = false,
  showStatusFilter = false,
  users = [],
}: Props): React.JSX.Element {
  const { data: dispositions } = useDispositions(true);
  const [selectedUser, setSelectedUser] = useState<SelectValue>(null);
  const [selectedStatus, setSelectedStatus] = useState<SelectValue>(null);
  const [selectedActivity, setSelectedActivity] = useState<SelectValue>(null);
  const [selectedClosedReason, setSelectedClosedReason] =
    useState<SelectValue>(null);
  const [dateTypeFilter, setDateTypeFilter] = useState<string>(
    DATE_TYPE.COMPLETED_DATE
  );
  const filterRef = useRef<HTMLDivElement>(null);
  const toDateRef = useRef<ReactDatePicker<never, undefined>>(null);

  useClickOutside(filterRef, () => handleFilter());

  const getClosedReasonOptions = (): Option[] => {
    return (
      dispositions?.data.map((disposition) => ({
        value: disposition.code,
        label: disposition.name,
      })) || []
    );
  };

  const getInitialState = (): FilterState => {
    const initialBaseline = columnFilters.find(
      (column) => column.id === "baseline"
    ) as ColumnFilter;
    const initialCurrent = columnFilters.find(
      (column) => column.id === "current"
    ) as ColumnFilter;
    const initialAssignee = columnFilters.find(
      (column) => column.id === "assignee"
    ) as ColumnFilter;
    const initialStatus = columnFilters.find(
      (column) => column.id === "status"
    ) as ColumnFilter;
    const initialClosedReason = columnFilters.find(
      (column) => column.id === "closed_reason"
    ) as ColumnFilter;
    return {
      dateRangeOption: dateRangeSelection.key,
      dateRange: {
        from: dateRangeSelection.startDate || new Date(),
        to: dateRangeSelection.endDate || new Date(),
      },
      baselineRange: {
        from: initialBaseline?.value[0] || undefined,
        to: initialBaseline?.value[1] || undefined,
      },
      currentRange: {
        from: initialCurrent?.value[0] || undefined,
        to: initialCurrent?.value[1] || undefined,
      },
      assignee: initialAssignee?.value || "",
      status: initialStatus?.value || [],
      closedReason: initialClosedReason?.value || [],
    } as FilterState;
  };
  const [state, setState] = useState<FilterState>(getInitialState());
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    if (state.assignee) {
      const user = (users || []).find(
        (item) => state.assignee === `${item.first_name} ${item.last_name}`
      );
      if (user) {
        setSelectedUser({
          value: user.id.toString(),
          label: getAssigneeFullName(user.first_name, user.last_name),
        });
      }
    } else {
      setSelectedUser(null);
    }
    if (Array.isArray(state.closedReason)) {
      setSelectedClosedReason(
        state.closedReason.map(
          (reason) =>
            (getClosedReasonOptions().find(
              (s) => reason === s?.value
            ) as Option) || ({} as Option)
        )
      );
    } else {
      setSelectedClosedReason([]);
    }
    if (!Array.isArray(state.status)) {
      const status = getStatusOptions().find(
        (s) => state.status === s?.label
      ) as Option;
      setSelectedStatus(status ? [status] : []);
    } else if (state.status) {
      setSelectedStatus(
        state.status.map(
          (status) =>
            (getStatusOptions().find((s) => status === s?.label) as Option) ||
            ({} as Option)
        )
      );
    } else {
      setSelectedStatus(null);
    }
    if (state.activityCount) {
      const activityCount = getActivityOptions().find(
        (s) => s.label === state.activityCount.toString()
      ) as Option;
      setSelectedActivity(activityCount);
    } else {
      setSelectedActivity(null);
    }
  }, [users, state]);

  const handleFilter = () => {
    if (showFilter) {
      const newState = getInitialState();
      setState(newState);
    }
    setShowFilter(!showFilter);
  };

  const handleDateRangeOptionChange = (e: any) => {
    const value = e.target.value;
    if (value !== DATE_RANGE_OPTIONS.CUSTOM) {
      let from: Date;
      let to: Date;
      switch (value) {
        case DATE_RANGE_OPTIONS.TODAY: {
          from = new Date();
          to = new Date();
          break;
        }
        case DATE_RANGE_OPTIONS.THIS_WEEK: {
          from = startOfWeek(new Date());
          to = endOfWeek(new Date());
          break;
        }
        case DATE_RANGE_OPTIONS.THIS_MONTH: {
          from = startOfMonth(new Date());
          to = endOfMonth(new Date());
          break;
        }
      }
      setState((s) => ({
        ...s,
        dateRangeOption: value,
        dateRange: {
          from,
          to,
        },
      }));
    } else {
      setState((s) => ({
        ...s,
        dateRangeOption: value,
      }));
    }
  };

  const handleApplyFilter = (event: any) => {
    event.preventDefault();

    onDateRangeChange({
      startDate: state.dateRange.from,
      endDate: state.dateRange.to,
      key: state.dateRangeOption,
      type: dateTypeFilter,
    });
    const columnFilters: ColumnFiltersState = [];
    if (state.baselineRange.from || state.baselineRange.to) {
      columnFilters.push({
        id: "baseline",
        value: [
          state.baselineRange.from?.toString() ?? "",
          state.baselineRange.to?.toString() ?? "",
        ],
      });
    }
    if (state.currentRange.from || state.currentRange.to) {
      columnFilters.push({
        id: "current",
        value: [
          state.currentRange.from?.toString() ?? "",
          state.currentRange.to?.toString() ?? "",
        ],
      });
    }
    if (state.assignee) {
      columnFilters.push({
        id: "assignee",
        value: state.assignee,
      });
    }
    if (state.status) {
      columnFilters.push({
        id: "status",
        value: state.status,
      });
    }
    if (state.closedReason.length) {
      columnFilters.push({
        id: "closed_reason",
        value: state.closedReason,
      });
    }
    if (state.activityCount) {
      columnFilters.push({
        id: "activityCount",
        value: state.activityCount,
      });
    }
    if (setColumnFilters) {
      setColumnFilters(columnFilters);
    }
    setShowFilter(false);
  };

  const onCustomInputChange = (
    e: any,
    rangeName: "baselineRange" | "currentRange",
    rangePosition: "from" | "to"
  ) =>
    setState((s) => ({
      ...s,
      [rangeName]: {
        ...s[rangeName],
        [rangePosition]: e.target.value ? Number(e.target.value) : null,
      },
    }));

  const onSelectChange = (
    item: SelectValue,
    property: string,
    useLabelProperty = true
  ) => {
    if (item === null) {
      setState((s) => ({
        ...s,
        [property]: "",
      }));
    } else if (item) {
      if (!Array.isArray(item)) {
        setState((s) => ({
          ...s,
          [property]: item["label"] || "",
        }));
      } else {
        setState((s) => ({
          ...s,
          [property]: item.map(
            (item) => item[useLabelProperty ? "label" : "value"]
          ),
        }));
      }
    }
  };

  const dateTypeSelectedButtonClass =
    "bg-brand-blue-600 border-brand-blue-600 text-white hover:bg-blue-900";
  const dateTypeDefaultButtonClass =
    "border-gray-300 bg-white text-gray-700 hover:bg-white/10";

  return (
    <>
      <button
        className="flex flex-row rounded-md border border-gray-300 bg-white px-4 py-3 text-sm text-gray-700 hover:bg-white/10"
        onClick={handleFilter}
      >
        <FunnelIcon className="mr-2 h-5 w-5 cursor-pointer" />
        Filter
      </button>
      {showFilter && (
        <div
          ref={filterRef}
          className="absolute right-0 top-[50px] z-20 rounded-md border  border-gray-300 bg-white pb-[40px] transition-transform ease-in-out"
        >
          <form onSubmit={handleApplyFilter}>
            <div className="relative flex h-auto max-h-[470px] w-[420px] flex-col justify-start overflow-y-auto py-4">
              <h1 className="border-b border-gray-200 px-6 pb-4 text-lg font-semibold">
                Filter by
              </h1>
              {showAssigneeFilter && (
                <div className="px-6 py-3">
                  <h3 className="mb-2">Assignee</h3>
                  <UserSelector
                    selectedUser={selectedUser}
                    handleChange={(item: SelectValue) =>
                      onSelectChange(item as Option, "assignee")
                    }
                    isMultiple={false}
                    isSearchable={true}
                    isClearable={true}
                  />
                </div>
              )}
              {showStatusFilter && (
                <div className=" px-6 py-3">
                  <h3 className="mb-2">Status</h3>
                  <Select
                    value={selectedStatus}
                    options={getStatusOptions()}
                    onChange={(item: SelectValue) =>
                      onSelectChange(item, "status")
                    }
                    isMultiple={true}
                    isSearchable={false}
                    isClearable={true}
                    primaryColor={""}
                  />
                </div>
              )}
              {showStatusFilter && (
                <div className="px-6 py-3">
                  <h3 className="mb-2">Disposition</h3>
                  <Select
                    value={selectedClosedReason}
                    options={getClosedReasonOptions()}
                    onChange={(item: SelectValue) =>
                      onSelectChange(item, "closedReason", false)
                    }
                    isMultiple={true}
                    isSearchable={false}
                    isClearable={true}
                    primaryColor={""}
                  />
                </div>
              )}
              <div className="px-6 py-3">
                <h3 className="mb-2">Activities</h3>
                <Select
                  value={selectedActivity}
                  options={getActivityOptions()}
                  onChange={(item: SelectValue) =>
                    onSelectChange(item, "activityCount")
                  }
                  isMultiple={false}
                  isSearchable={false}
                  isClearable={true}
                  primaryColor={""}
                />
              </div>
              <div className="mb-4 mt-4 border-t border-gray-200 px-6 py-3">
                <div className="flex items-center justify-between pb-2">
                  <h3 className="mb-2">Date range</h3>
                  <div className="flex items-center justify-center">
                    <a
                      className={classNames(
                        "flex cursor-pointer appearance-none flex-row whitespace-nowrap rounded-bl-md rounded-tl-md border px-2 py-1 text-xs",
                        dateTypeFilter === DATE_TYPE.LAST_UPDATED
                          ? dateTypeSelectedButtonClass
                          : dateTypeDefaultButtonClass
                      )}
                      onClick={() => setDateTypeFilter(DATE_TYPE.LAST_UPDATED)}
                    >
                      Last Updated
                    </a>
                    <a
                      className={classNames(
                        "flex cursor-pointer appearance-none flex-row whitespace-nowrap rounded-br-md rounded-tr-md border px-2 py-1 text-xs",
                        dateTypeFilter === DATE_TYPE.COMPLETED_DATE
                          ? dateTypeSelectedButtonClass
                          : dateTypeDefaultButtonClass
                      )}
                      onClick={() =>
                        setDateTypeFilter(DATE_TYPE.COMPLETED_DATE)
                      }
                    >
                      Created
                    </a>
                  </div>
                </div>
                <select
                  className="block w-full rounded-lg border !border-gray-300 p-2.5 text-sm text-gray-900"
                  onChange={handleDateRangeOptionChange}
                  value={state.dateRangeOption}
                >
                  <option value={DATE_RANGE_OPTIONS.TODAY}>Today</option>
                  <option value={DATE_RANGE_OPTIONS.THIS_WEEK}>
                    This Week
                  </option>
                  <option value={DATE_RANGE_OPTIONS.THIS_MONTH}>
                    This Month
                  </option>
                  <option value={DATE_RANGE_OPTIONS.CUSTOM}>Custom</option>
                </select>
                <div className=" mt-2 flex items-center justify-between gap-2">
                  <div>
                    <label
                      className="w-1/2 text-sm text-gray-400"
                      id="fromDatePicker"
                    >
                      From
                    </label>
                    <DatePicker
                      id="fromDatePicker"
                      dateFormat={DateTableFormat}
                      selected={state.dateRange.from}
                      disabled={
                        state.dateRangeOption !== DATE_RANGE_OPTIONS.CUSTOM
                      }
                      shouldCloseOnSelect={true}
                      onChange={(date, e) => {
                        if (date) {
                          toDateRef.current?.setFocus();
                          e?.currentTarget.blur();
                          const to =
                            state.dateRange.to < date
                              ? date
                              : state.dateRange.to;
                          setState((s) => ({
                            ...s,
                            dateRange: { ...s.dateRange, from: date, to: to },
                          }));
                        }
                      }}
                      className="block w-full !rounded-lg border border-gray-300 p-2.5 text-sm text-gray-900 disabled:text-gray-400"
                    />
                  </div>
                  <div>
                    <label
                      className="w-1/2 text-sm text-gray-400"
                      id="toDatePicker"
                    >
                      To
                    </label>
                    <DatePicker
                      id="toDatePicker"
                      ref={toDateRef}
                      dateFormat={DateTableFormat}
                      selected={state.dateRange.to}
                      minDate={state.dateRange.from}
                      shouldCloseOnSelect={true}
                      disabled={
                        state.dateRangeOption !== DATE_RANGE_OPTIONS.CUSTOM
                      }
                      onChange={(date) => {
                        if (date) {
                          setState((s) => ({
                            ...s,
                            dateRange: { ...s.dateRange, to: date },
                          }));
                        }
                      }}
                      className="block w-full !rounded-lg border border-gray-300 p-2.5 text-sm text-gray-900 disabled:text-gray-400"
                    />
                  </div>
                </div>
                <h3 className="mb-2 mt-4">Baseline range</h3>
                <div className=" mt-2 flex items-center justify-between gap-2">
                  <label className=" text-sm text-gray-400">
                    From
                    <CustomInput
                      value={state.baselineRange.from}
                      icon="$"
                      type={InputType.NUMBER}
                      onChange={(e) =>
                        onCustomInputChange(e, "baselineRange", "from")
                      }
                    />
                  </label>
                  <label className=" text-sm text-gray-400">
                    To
                    <CustomInput
                      value={state.baselineRange.to}
                      icon="$"
                      type={InputType.NUMBER}
                      onChange={(e) =>
                        onCustomInputChange(e, "baselineRange", "to")
                      }
                    />
                  </label>
                </div>
                <h3 className="mb-2 mt-4">Current range</h3>
                <div className=" mt-2 flex items-center justify-between gap-2">
                  <label className=" text-sm text-gray-400">
                    From
                    <CustomInput
                      value={state.currentRange.from}
                      icon="$"
                      type={InputType.NUMBER}
                      onChange={(e) =>
                        onCustomInputChange(e, "currentRange", "from")
                      }
                    />
                  </label>
                  <label className=" text-sm text-gray-400">
                    To
                    <CustomInput
                      value={state.currentRange.to}
                      icon="$"
                      type={InputType.NUMBER}
                      onChange={(e) =>
                        onCustomInputChange(e, "currentRange", "to")
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="absolute bottom-0 left-0 z-[21] flex w-full items-center justify-end gap-3 overflow-hidden rounded-b-lg border-t border-gray-200 bg-white px-6 py-3">
              <button
                type="button"
                className="rounded-md border border-gray-300 bg-white px-2 py-1 text-xs text-gray-700 hover:bg-white/10"
                onClick={() => {
                  handleFilter();
                }}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="rounded-md px-2 py-1 text-xs text-white default:bg-[#2C56B6] hover:bg-blue-900"
                onClick={handleApplyFilter}
              >
                Apply
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
